import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import "../../../App.css";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/custom.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import generateUrl from "../../../helpers/urlHelper";
import { useFormik } from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Stack, TextField } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TimePicker from "rc-time-picker";
import DialogContentText from "@mui/material/DialogContentText";
import Checkbox from "@mui/material/Checkbox";
import {
    Appointmenttype,
    addFindSupport,
    editFindSupport,
    getFindSupportList,
} from "../../../api";
import {
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    FormGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import localstorageData from "../../../helpers/localstorageData";

const urlLink = generateUrl();

export default function FindSupport() {
    let userData = localstorageData();
    const [shiftType, setShiftType] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [editId, setEditId] = useState(userData._id);
    const [arrOfApp, setArrOfApp] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);
    const [dateCount, setDateCount] = React.useState(1);
    const [dateAll, setDateAll] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [showError, setShowError] = React.useState({
        startTime: "",
        endTime: ""
    });
    const [dateTimes, setDateTimes] = useState([
        { date: "", startTime: "", endTime: "" }, // Initialize with empty values
    ]);

    const weeks = [];
    for (let i = 0; i < dateAll?.length; i += 7) {
        weeks.push(dateAll.slice(i, i + 7));
    }
    useEffect(() => {
        getAppointment();
    }, []);
    const format1 = "HH:mm";
    function formatDate(date) {
        const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const day = days[date.getDay()];
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const dayOfMonth = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear().toString().substr(-4); // Get last 4 digits of the year

        return `${day} ${dayOfMonth}-${month}-${year}`;
    }

    const addDatesWithApp = (isChecked, date) => {
        if (isChecked) {
            setSelectedDateTime((prev) => [
                ...prev,
                { date, starttime: startTime, endtime: endTime },
            ]);
        } else {
            setSelectedDateTime((prev) => prev.filter((dt) => !(dt.date === date)));
        }
    };

    function generateDateList(numOfDays) {
        const today = new Date(Date.now()); // Get today's date
        const dates = [];

        for (let i = 0; i < numOfDays; i++) {
            const date = new Date(today); // Create a new date object
            date.setDate(today.getDate() + i); // Increment date by 'i' days
            const formattedDate = formatDate(date); // Format date
            dates.push(formattedDate); // Add formatted date to the array
        }
        return dates;
    }

    useEffect(() => {
        const dates = generateDateList(dateCount);
        setDateAll(dates);
    }, [dateCount]);

    const [weekSelections, setWeekSelections] = React.useState([]);
    const [selectAllWeeks, setSelectAllWeeks] = React.useState(false);
    React.useEffect(() => {
        // Initialize the weekSelections state based on the weeks array
        const initialSelections = weeks.map((week) =>
            week.every((date) => arrOfApp.includes(date.split(" ")[1]))
        );
        // setWeekSelections(initialSelections);

        // Determine if all weeks are selected
        const allWeeksSelected = dateAll?.every((date) =>
            arrOfApp.includes(date.split(" ")[1])
        );
        setSelectAllWeeks(allWeeksSelected);
    }, [weeks, arrOfApp, dateAll]);

    const handleIndividualCheckboxChange = (event, date) => {
        const isChecked = event.target.checked;

        // Assuming addDatesWithApp function handles addition or removal based on isChecked
        addDatesWithApp(isChecked, date);
    };

    const getAppointment = () => {
        axios
            .get(`${Appointmenttype}?createdBy=${userData.createdBy}`)
            .then(async (res) => {
                let AppintData = res?.data?.data;
                let data = [];

                AppintData &&
                    AppintData.map((AppoitmentType) => {
                        data.push({
                            label: `${AppoitmentType?.AppoitmentType}`,
                            id: AppoitmentType?._id,
                            icon: AppoitmentType?.icon,
                        });
                    });
                console.log("datadatadata", data);
                setShiftType(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
    
        // Select or deselect all dates based on isChecked
        weeks.forEach((week) => {
            week.forEach((date) => addDatesWithApp(isChecked, date.split(" ")[1]));
        });
    
        // Update weekSelections state to reflect all weeks selected or deselected
        const updatedSelections = weeks.map(() => isChecked);
        setWeekSelections(updatedSelections);
    };

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
    }));

    const initialValues = {
        supportPerWeekInHrs: "",
        clientId: "",
        whenSupport: "",
        shiftType: "",
        requiredFor: "",
        status: "",
    };

    const handleSelectAllWeekWise = (event, weekIndex) => {
        const isChecked = event.target.checked;
        const updatedSelections = [...weekSelections];
        updatedSelections[weekIndex] = isChecked;

        // Select all dates in the week if isChecked is true
        if (isChecked) {
            weeks[weekIndex].forEach((date) =>
                addDatesWithApp(true, date.split(" ")[1])
            ); // Assuming addDatesWithApp function handles addition to selectedDateTime
        } else {
            weeks[weekIndex].forEach((date) =>
                addDatesWithApp(false, date.split(" ")[1])
            ); // Assuming addDatesWithApp function handles removal from selectedDateTime
        }
        if(selectAll === true && isChecked === false){
            setSelectAll(false)
        }
        setWeekSelections(updatedSelections);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const convertDate = (dateString) => {
        // Assuming dateString is in the format 'DD-MM-YYYY'
        const parts = dateString.split("-");
        // parts[0] is DD, parts[1] is MM, parts[2] is YYYY
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    const isDateInPast = (dateString) => {
        const date = convertDate(dateString);
        const today = new Date();
        // Remove the time portion of the dates
        date.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return date < today;
    };

    useEffect(() => {
        if (editId) {
            getFindSupport();
        }
    }, [editId]);

    const getFindSupport = () => {
        axios
            .get(`${getFindSupportList}/${editId}`)
            .then((res) => {
                console.log("res ress", res);
                const result = res?.data?.data;
                formik.setValues({
                    clientId: result.clientId || "",
                    whenSupport: result.whenSupport || "",
                    supportPerWeekInHrs: result.supportPerWeekInHrs || "",
                    shiftType: result.shiftType || "",
                    requiredFor: result.requiredFor || "",
                    supportTo: result.supportTo || "",
                });
                setStartTime(result?.supportTo?.[0]?.starttime)
                setEndTime(result?.supportTo?.[0]?.endtime)
                setSelectedDateTime(result.supportTo ? result.supportTo : []);
            })
            .catch((error) => {
                console.log("Error::", error);
            });
    };


    const submitCommonRecord = () => {
        console.log("gdffdfdsddswdewdw", startTime, endTime);
        if (startTime == undefined && endTime == undefined) {
            setShowError({
                startTime: "Start Time is required",
                endTime: "End Time is required"
            })
        } else {
            setSelectedDateTime((prev) =>
                prev.map((dt) => ({
                    ...dt,
                    starttime: startTime,
                    endtime: endTime,
                }))
            );
            setShowError({
                startTime: "",
                endTime: ""
            })
            handleClose();
        }

    };

    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            setDisableButton(true);

            const uniqueArray = selectedDateTime?.length > 0 && selectedDateTime?.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.date === item.date && t.starttime === item.starttime && t.endtime === item.endtime
                ))
            );

            values.createdBy = editId;
            values.createdBy = editId;
            values.updatedBy = editId;
            values.clientId = editId;
            values.supportTo = uniqueArray;
            values.status = "Pending";
            if (!values.shiftType) {
                setDisableButton(false);
                toast.error("Shift type is required!");
            } else {
                axios
                    .post(`${addFindSupport}/${editId}`, values)
                    .then((res) => {
                        if (res.data.success === true) {
                            setDisableButton(false);
                            toast.success(res.data.message);
                            // setTimeout(() => {
                            //     navigate(`/${userTypeUrl}/list-calendar`);
                            // }, 500);
                        } else {
                            setDisableButton(false);
                            toast.error(res.data.message);
                        }
                    })
                    .catch((error) => {
                        setDisableButton(false);
                        toast.error("Something went wrong");
                    });
            }
        },
    });

    const [selectedDateTime, setSelectedDateTime] = useState([]);
    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("00:00");

    const now = moment();

    const disabledHours = () => {
        const hours = [];
        for (let i = 0; i < now.hour(); i++) {
            hours.push(i);
        }
        return hours;
    };

    const disabledMinutes = (selectedHour) => {
        const minutes = [];
        if (selectedHour === now.hour()) {
            for (let i = 0; i <= now.minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    };

    return (
        <Box className="main">
            <Box id="tabs" className="w-100">
                {/* <Box container className="dashboard-form-typo dashboard-form-typo-up"> */}
                <Grid container className="d-flex header-topsection">
                    <Grid item xs={6} sm={6} md={6}>
                        <Box id="breadcrumb">
                            <Link to={"/" + urlLink + "/home"}>
                                {" "}
                                <ArrowBackIosIcon />{" "}
                            </Link>{" "}
                            <Box className="breadcrumb-inr">
                                <h4 className="d-flex align-items-center ">Find Support</h4>
                                <Box className="breadcrumbs">
                                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                                        Find Support
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box className="appointment-tabBox padding-left-20 padding-right-20">
                    <h3 className="" style={{ padding: "10px 0" }}>
                        When do you need Support
                    </h3>
                    <hr />
                    <form onSubmit={formik.handleSubmit}>
                        <Box className="personal-info-main" style={{ paddingTop: "10px" }}>
                            <Box className="form-heading-section"></Box>
                            <Box className="personal-info">
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <label>
                                            How many hours of support do you require per week?
                                        </label>

                                        <RadioGroup
                                            row
                                            aria-label="support-type"
                                            name="supportPerWeekInHrs"
                                            sx={{ p: 2, ml: -2 }}
                                        >
                                            <FormControlLabel
                                                onClick={() => {
                                                    formik.setFieldValue(
                                                        "supportPerWeekInHrs",
                                                        "1-5 hours"
                                                    );
                                                }}
                                                value="1-5 hours"
                                                control={<Radio />}
                                                label="1-5 hours"
                                                checked={
                                                    formik.values.supportPerWeekInHrs === "1-5 hours"
                                                        ? true
                                                        : ""
                                                }
                                            />
                                            &nbsp;&nbsp;
                                            <FormControlLabel
                                                onClick={() => {
                                                    formik.setFieldValue(
                                                        "supportPerWeekInHrs",
                                                        "6-11 hours"
                                                    );
                                                }}
                                                value="6-11 hours"
                                                control={<Radio />}
                                                label="6-11 hours"
                                                checked={
                                                    formik.values.supportPerWeekInHrs === "6-11 hours"
                                                        ? true
                                                        : ""
                                                }
                                            />
                                            &nbsp;&nbsp;
                                            <FormControlLabel
                                                onClick={() => {
                                                    formik.setFieldValue(
                                                        "supportPerWeekInHrs",
                                                        "11-15 hours"
                                                    );
                                                }}
                                                value="11-15 hours"
                                                control={<Radio />}
                                                label="11-15 hours"
                                                checked={
                                                    formik.values.supportPerWeekInHrs === "11-15 hours"
                                                        ? true
                                                        : ""
                                                }
                                            />
                                            &nbsp;&nbsp;
                                            <FormControlLabel
                                                onClick={() => {
                                                    formik.setFieldValue(
                                                        "supportPerWeekInHrs",
                                                        "More than 15 hours"
                                                    );
                                                }}
                                                value="More than 15 hours"
                                                control={<Radio />}
                                                label="More than 15 hours"
                                                checked={
                                                    formik.values.supportPerWeekInHrs ===
                                                        "More than 15 hours"
                                                        ? true
                                                        : ""
                                                }
                                            />
                                            &nbsp;&nbsp;
                                            <FormControlLabel
                                                onClick={() => {
                                                    formik.setFieldValue(
                                                        "supportPerWeekInHrs",
                                                        "Unsure at this stage"
                                                    );
                                                }}
                                                value="Unsure at this stage"
                                                control={<Radio />}
                                                label="Unsure at this stage"
                                                checked={
                                                    formik.values.supportPerWeekInHrs ===
                                                        "Unsure at this stage"
                                                        ? true
                                                        : ""
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Box>
                            <Box className="personal-info">
                                <Grid item xs={12}>
                                    <FormControl>
                                        <label>When do you require Support?</label>
                                        <RadioGroup
                                            row
                                            aria-label="support-type"
                                            name="whenSupport"
                                            sx={{ p: 2, ml: -2 }}
                                        >
                                            <FormControlLabel
                                                onClick={() => {
                                                    formik.setFieldValue("whenSupport", "Weekly");
                                                }}
                                                value="Weekly"
                                                control={<Radio />}
                                                label="Weekly"
                                                checked={
                                                    formik.values.whenSupport === "Weekly" ? true : ""
                                                }
                                            />
                                            &nbsp;&nbsp;
                                            <FormControlLabel
                                                onClick={() => {
                                                    formik.setFieldValue("whenSupport", "Fortnightly");
                                                }}
                                                value="Fortnightly"
                                                control={<Radio />}
                                                label="Fortnightly"
                                                checked={
                                                    formik.values.whenSupport === "Fortnightly"
                                                        ? true
                                                        : ""
                                                }
                                            />
                                            &nbsp;&nbsp;
                                            <FormControlLabel
                                                onClick={() => {
                                                    formik.setFieldValue("whenSupport", "Monthly");
                                                }}
                                                value="Monthly"
                                                control={<Radio />}
                                                label="Monthly"
                                                checked={
                                                    formik.values.whenSupport === "Monthly" ? true : ""
                                                }
                                            />
                                            &nbsp;&nbsp;
                                            <FormControlLabel
                                                onClick={() => {
                                                    formik.setFieldValue("whenSupport", "Quarterly");
                                                }}
                                                value="Quarterly"
                                                control={<Radio />}
                                                label="Quarterly"
                                                checked={
                                                    formik.values.whenSupport === "Quarterly" ? true : ""
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Box>
                            {/* {shiftType} */}
                            <Box className="personal-info">
                                <Grid item xs={12}>
                                    <FormControl>
                                        <label>Shift Type</label>
                                        <RadioGroup
                                            row
                                            aria-label="support-type"
                                            name="shiftType"
                                            sx={{ p: 2, ml: -2 }}
                                        >
                                            {shiftType?.length > 0 &&
                                                shiftType?.map((row) => (
                                                    <>
                                                        <img
                                                            className="checkbox-img gest-onboar-step4"
                                                            src={row?.icon?.location}
                                                            alt={row?.label}
                                                        />
                                                        &nbsp;&nbsp;
                                                        <FormControlLabel
                                                            onClick={() => {
                                                                formik.setFieldValue("shiftType", row?.id);
                                                            }}
                                                            // value="Weekly"
                                                            control={<Radio />}
                                                            label={row?.label}
                                                            checked={
                                                                formik?.values?.shiftType === row?.id
                                                                    ? true
                                                                    : ""
                                                            }
                                                        />
                                                        &nbsp;&nbsp;
                                                    </>
                                                ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Box>

                            <Box className="personal-info">
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <label>Required For</label>
                                        <RadioGroup
                                            row
                                            aria-label="support-type"
                                            name="requiredFor"
                                            onChange={(event) => {
                                                formik.handleChange(event);
                                                const value = event.target.value;
                                                // formik.setFieldValue("requiredFor", value);
                                                if (value === "Everyday") {
                                                    setDateCount(7);
                                                } else if (value === "Week Days") {
                                                    setDateCount(14);
                                                }
                                                setOpen(true);
                                            }}
                                            sx={{ p: 2, ml: -2 }}
                                        >
                                            <FormControlLabel
                                                value="Everyday"
                                                control={<Radio />}
                                                label="Everyday"
                                                checked={
                                                    formik.values.requiredFor === "Everyday" ? true : ""
                                                }
                                            />
                                            &nbsp;&nbsp;
                                            <FormControlLabel
                                                value="Week Days"
                                                control={<Radio />}
                                                label="Week Days"
                                                checked={
                                                    formik.values.requiredFor === "Week Days" ? true : ""
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Box>

                            <Box className="agency-btn-section agency-btn-section-vk">
                                {/* <a onClick={handleCancelClick} >Cancel</a> */}
                                <Link to={"/client/find-support"}>Cancel</Link>
                                <button type="submit" disabled={disableButton}>
                                    {" "}
                                    {disableButton === true ? "Processing..." : "Update"}{" "}
                                </button>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="attach-file-modals"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box className="d-flex justify-content-center p-3">
                            <Box className="add-expenses-popup">
                                <Box className="d-flex align-items-center justify-content-between">
                                    <Typography component="div" fontWeight="bold" fontSize="16px">
                                        {" "}
                                        {/* {`Add Shift type - ${props?.shiftType} for Health Carer ${props?.hcName.map(res => res?.label?.split('(')[0]).join(', ')}:`} */}
                                    </Typography>
                                    <CloseIcon onClick={handleClose} />
                                </Box>
                                <Box className="" style={{ margin: "20px 0" }}>
                                    <Box className="item">
                                        <Box className="d-flex align-items-center justify-content-between">
                                            <Grid
                                                container
                                                spacing={2}
                                                className="align-items-center"
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                    className="input d-flex flex-column pt-0"
                                                >
                                                    <label className="margin-label-bottom">
                                                        Start Time <span className="red-color">*</span>
                                                    </label>

                                                    <TimePicker
                                                        // disabled={props?.appointmentDetailForSda?.[props?.shiftType]?.editTimePer == true ? false : true}
                                                        placeholder={`00:00`}
                                                        showSecond={false}
                                                        // name={`appointmentDetailForSda.${props?.shiftType}.${props?.date}.startTime`}
                                                        className="aad-appt date-picker1"
                                                        value={
                                                            startTime ? moment(startTime, format1) : null
                                                        }
                                                        onChange={(time) =>
                                                            setStartTime(time.format(format1))
                                                        }
                                                        format={format1}
                                                        use24Hours
                                                        inputReadOnly
                                                        //disabledHours={disabledHours}
                                                        //disabledMinutes={disabledMinutes}
                                                        // value={props?.appointmentDetailForSda?.[props?.shiftType]?.editTimePer == true ?
                                                        //     allUpdateRecord[props.shiftType]?.[props?.date]?.startTime !==
                                                        //         null &&
                                                        //         allUpdateRecord[props.shiftType]?.[props?.date]?.startTime !==
                                                        //         undefined &&
                                                        //         allUpdateRecord[props.shiftType]?.[props?.date]?.startTime !== ""
                                                        //         ? moment(
                                                        //             allUpdateRecord[props.shiftType]?.[props?.date]?.startTime,
                                                        //             format1
                                                        //         )
                                                        //         : moment(
                                                        //             props?.appointmentDetailForSda?.[props?.shiftType]?.startTime ? props?.appointmentDetailForSda?.[props?.shiftType]?.startTime : props?.shiftTypeData?.startTime,

                                                        //             format1
                                                        //         ) : moment(
                                                        //             props?.appointmentDetailForSda?.[props?.shiftType]?.startTime ? props?.appointmentDetailForSda?.[props?.shiftType]?.startTime : props?.shiftTypeData?.startTime,

                                                        //             format1
                                                        //         )
                                                        // }
                                                        // value={props?.appointmentDetailForSda?.[props?.shiftType]?.editTimePer == true ?
                                                        //     props?.appointmentDetailForSda?.[props?.shiftType]?.[props?.date]?.startTime !==
                                                        //         null &&
                                                        //         props?.appointmentDetailForSda?.[props?.shiftType]?.[props?.date]?.startTime !==
                                                        //         undefined &&
                                                        //         props?.appointmentDetailForSda?.[props?.shiftType]?.[props?.date]?.startTime !== ""
                                                        //         ? moment(
                                                        //             props?.appointmentDetailForSda?.[props?.shiftType]?.[props?.date]?.startTime,
                                                        //             format1
                                                        //         )
                                                        //         : moment(
                                                        //             props?.appointmentDetailForSda?.[props?.shiftType]?.startTime ? props?.appointmentDetailForSda?.[props?.shiftType]?.startTime : props?.shiftTypeData?.startTime,

                                                        //             format1
                                                        //         ) : moment(
                                                        //             props?.appointmentDetailForSda?.[props?.shiftType]?.startTime ? props?.appointmentDetailForSda?.[props?.shiftType]?.startTime : props?.shiftTypeData?.startTime,

                                                        //             format1
                                                        //         )
                                                        // }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                size="small"
                                                                // name={`appointmentDetailForSda.${props?.shiftType}.${props?.date}.startTime`}
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <p style={{ color: "red", fontSize: "12px" }}>{showError?.startTime}</p>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                    className="input d-flex flex-column pt-0"
                                                >
                                                    <label className="margin-label-bottom">
                                                        End Time <span className="red-color">*</span>
                                                    </label>
                                                    <TimePicker
                                                        // disabled={props?.appointmentDetailForSda?.[props?.shiftType]?.editTimePer == true ? false : true}
                                                        //  style={{ width: "150px" }}
                                                        placeholder={`00:00`}
                                                        showSecond={false}
                                                        // name={`appointmentDetailForSda.${props?.shiftType}.${props?.date}.endTime`}
                                                        // className="form-input"
                                                        className="aad-appt date-picker1"
                                                        value={endTime ? moment(endTime, format1) : null}
                                                        onChange={(time) =>
                                                            setEndTime(time.format(format1))
                                                        }
                                                        // onChange={(e) => {
                                                        //     DateChange(e, props?.shiftType, props?.date, props?.setFieldValue);
                                                        // }}

                                                        format={format1}
                                                        use24Hours
                                                        inputReadOnly
                                                        disabledHours={disabledHours}
                                                        disabledMinutes={disabledMinutes}
                                                        // value={
                                                        //     props?.appointmentDetailForSda?.[props?.shiftType]?.editTimePer == true ?
                                                        //         allUpdateRecord[props.shiftType]?.[props?.date]?.endTime !==
                                                        //             null &&
                                                        //             allUpdateRecord[props.shiftType]?.[props?.date]?.endTime !==
                                                        //             undefined &&
                                                        //             allUpdateRecord[props.shiftType]?.[props?.date]?.endTime !== ""
                                                        //             ? moment(
                                                        //                 allUpdateRecord[props.shiftType]?.[props?.date]?.endTime,
                                                        //                 format1
                                                        //             )
                                                        //             : moment(
                                                        //                 props?.appointmentDetailForSda?.[props?.shiftType]?.endTime ? props?.appointmentDetailForSda?.[props?.shiftType]?.endTime : props?.shiftTypeData?.endTime,

                                                        //                 format1
                                                        //             ) : moment(
                                                        //                 props?.appointmentDetailForSda?.[props?.shiftType]?.endTime ? props?.appointmentDetailForSda?.[props?.shiftType]?.endTime : props?.shiftTypeData?.endTime,

                                                        //                 format1
                                                        //             )
                                                        // }
                                                        // value={
                                                        //     props?.appointmentDetailForSda?.[props?.shiftType]?.editTimePer == true ?
                                                        //         props?.appointmentDetailForSda?.[props?.shiftType]?.[props?.date]?.endTime !==
                                                        //             null &&
                                                        //             props?.appointmentDetailForSda?.[props?.shiftType]?.[props?.date]?.endTime !==
                                                        //             undefined &&
                                                        //             props?.appointmentDetailForSda?.[props?.shiftType]?.[props?.date]?.endTime !== ""
                                                        //             ? moment(
                                                        //                 props?.appointmentDetailForSda?.[props?.shiftType]?.[props?.date]?.endTime,
                                                        //                 format1
                                                        //             )
                                                        //             : moment(
                                                        //                 props?.appointmentDetailForSda?.[props?.shiftType]?.endTime ? props?.appointmentDetailForSda?.[props?.shiftType]?.endTime : props?.shiftTypeData?.endTime,

                                                        //                 format1
                                                        //             ) : moment(
                                                        //                 props?.appointmentDetailForSda?.[props?.shiftType]?.endTime ? props?.appointmentDetailForSda?.[props?.shiftType]?.endTime : props?.shiftTypeData?.endTime,

                                                        //                 format1
                                                        //             )
                                                        // }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                size="small"
                                                                // name={`appointmentDetailForSda.${props?.shiftType}.${props?.date}.endTime`}
                                                                // sx={{ mt: 2, mb: 2, width: "100%" }}
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    placeholder: "00:00",
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <p style={{ color: "red", fontSize: "12px" }}>{showError?.endTime}</p>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    className="margin-top-20"
                                                >
                                                    <FormGroup row sx={{ p: 1 }}>
                                                        {weeks.length > 1 && (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectAll}
                                                                        onChange={handleSelectAll}
                                                                    />
                                                                }
                                                                label="All Weeks"
                                                            />
                                                        )}

                                                        {/* {props?.dates?.map((dates) => {
                                                const datePart = dates.split(' ')[1];
                                                return (
                                                    <>

                                                        <FormControlLabel
                                                            onClick={(event) => addDatesWithApp(event, dates.split(' ')[1])}
                                                            control={
                                                                <Checkbox
                                                                    disabled={isDateInPast(datePart)} // Disable checkbox if date is in the past
                                                                />
                                                            }
                                                            name={`appointmentDetailForSda.${props?.shiftType}.${props.date}.dates`}
                                                            label={dates}
                                                            checked={allUpdateRecord?.[props?.shiftType]?.[props?.date]?.dates?.includes(dates.split(' ')[1])}
                                                        />
                                                    </>
                                                )
                                            })} */}

                                                        {weeks.map((week, weekIndex) => (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                key={weekIndex}
                                                            >
                                                                <Stack>
                                                                    <Typography
                                                                        variant="p"
                                                                        style={{ fontWeight: "bold" }}
                                                                    >
                                                                        {weekIndex + 1} Week:
                                                                    </Typography>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={(e) =>
                                                                                    handleSelectAllWeekWise(e, weekIndex)
                                                                                }
                                                                                checked={
                                                                                    weekSelections[weekIndex] || false
                                                                                }
                                                                            />
                                                                        }
                                                                        label="All"
                                                                    />
                                                                </Stack>
                                                                {week.map((date) => (
                                                                    <FormControlLabel
                                                                        key={date}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={selectedDateTime?.some(
                                                                                    (dt) => dt.date === date.split(" ")[1]
                                                                                )}
                                                                                onChange={(event) =>
                                                                                    handleIndividualCheckboxChange(
                                                                                        event,
                                                                                        date.split(" ")[1]
                                                                                    )
                                                                                }
                                                                                disabled={isDateInPast(
                                                                                    date.split(" ")[1]
                                                                                )}
                                                                            />
                                                                        }
                                                                        // name={`appointmentDetailForSda.${props?.shiftType}.${props?.date}.dates`}
                                                                        label={date}
                                                                    />
                                                                ))}
                                                            </Grid>
                                                        ))}
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>

                                    <Box className="agency-btn-section add-expenses-btn">
                                        <Link
                                            onClick={() => {
                                                handleClose();
                                            }}
                                        >
                                            Cancel
                                        </Link>
                                        <Link
                                            onClick={() => {
                                                submitCommonRecord();
                                            }}
                                        >
                                            Save
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
