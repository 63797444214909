import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../../../Website/Web-Header";
import {
  Button,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/web-css.css";
import "../../../assets/css/Sa-style.css";
import "../../../assets/css/Appointment.css";
import "../../../assets/css/style.css";
import "../../../assets/css/custom.css";
import "../../../App.css";
import axios from "axios";
import nodata from "../../../assets/images/nodata.png";
import noImage from "../../../assets/images/noImage.png";
import { useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import MapImage from "../../../assets/images/google-maps-svg.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate, Link } from "react-router-dom";
import AvailabilityCalender from "./AvailabilityCalender";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  getDetailsPublicRosterAppointment,
  getHCLikedAppointment,
  setIndvHCinAppt,
  setFavAppointment,
  getClientRating,
  getIndvHCinAppt,
  getClientRiskAssesment,
} from "../../../api";

import { GoogleMap, Marker } from "@react-google-maps/api";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GradeIcon from "@mui/icons-material/Grade";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WebFooter from "../../../Website/Web-footer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewDetail = (props) => {
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?._id;
  let isSoleTrader = userData?.isSoleTrader;

  const location = useLocation();
  const clientId = location?.state?.clientId;
  const ApptId = location?.state?.ApptId;
  const Type = location?.state?.type
  let pathValue = location?.state?.pathValue;

  const [isLoading, setLoading] = useState(true);
  const [tabValue, settabValue] = React.useState(location?.state?.tabValue || 0)
  const [favourite, setFavourite] = useState([]);

  const mapRef = useRef(null);

  const [clientDetails, setClientDetails] = useState([]);
  const [clientUserData, setClientUserData] = useState([]);
  const [medicationData, setMedicationData] = useState([]);
  const [healthIssueData, setHealthIssueData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [apptHc, setApptHc] = useState();
  const [hcBlock, setHcBlock] = useState();

  const [stateData, setStateData] = useState();
  const [stateDataComm, setStateDataComm] = useState();
  const [stateDataHome, setStateDataHome] = useState();
  const [stateDataResp, setStateDataResp] = useState();
  const [stateDataSil, setStateDataSil] = useState();
  const [pendingCount, setPendingCount] = useState(0);

  const [hcCheckId, setHCCheck] = useState();

  const handleChange = (event, newValue) => {
    settabValue(newValue);
  };


  const insidePropsValue = props?.props?.page;
  if (insidePropsValue !== undefined) {
    pathValue = insidePropsValue;
  }

  useEffect(() => {
    GetApptHc();
  }, [ApptId]);

  const GetApptHc = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${getIndvHCinAppt}/${ApptId}?type=shiftSupport`);
      let dataval = res?.data?.data?.requestedIndHCId;
      setHcBlock(res?.data?.data);
      setApptHc(dataval);
    } catch (error) {
      console.log(error);
    }
  };

  //getIndvHCinAppt
  useEffect(() => {
    GetClientDetails();
  }, [ApptId]);

  useEffect(() => {
    if (hcBlock) {
      // Initialize pending count for each item
      let count = 0;
      hcBlock?.requestedIndHCBlock?.forEach((appointment) => {

        if (appointment.hcIsAppointed === "Pending") {
          count++;
        }
      });
      setPendingCount(count);
    }
  }, [hcBlock]);
  const GetClientDetails = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${getDetailsPublicRosterAppointment}/${ApptId}?type=${Type}`
      );
      let dataval = res?.data?.data[0];
      let clientUserData = dataval?.userDetails;



      setClientDetails(dataval?.userData);
      setMedicationData(dataval?.MedicationData);
      setHealthIssueData(dataval?.healthissuesData);
      setClientUserData(clientUserData);
      setLocationData(dataval?.Valcategories);

      setStateData(dataval?.stateDetails?.name);
      setStateDataComm(dataval?.stateDetailsComm?.name);
      setStateDataHome(dataval?.stateDetailsHome?.name);
      setStateDataResp(dataval?.stateDetailsResp?.name);
      setStateDataSil(dataval?.stateDetailsSil?.name);

      setHCCheck(dataval?.appointedIndHCId);
    } catch (error) {
      console.log(error);
    }
  };

  const addLikeApptFunction = async (ApptId, hcId, type) => {
    try {
      if (isSoleTrader === true) {
        axios
          .put(`${setFavAppointment}/${ApptId}/${hcId}/${type}`)
          .then((res) => {
            toast.success(res.data.message);
            GetClientDetails();
            HCLikeDislike();
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      } else {
        toast.error("Only ABN Holders Can Shortlist");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const addRequestFunction = async (ApptId, hcId, type, types, clientId) => {
    try {
      if (isSoleTrader === true) {
        axios
          .put(`${setIndvHCinAppt}/${ApptId}/${hcId}/${type}/${types}/${clientId}`)
          .then((res) => {
            toast.success(res.data.message);

            if (type == "send") {
              setTimeout(() => {
                navigate("/health-carer/appointment-connection-list");
              }, 500);
            } else {
              GetClientDetails();
              GetApptHc();
            }
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      } else {
        toast.error("Only ABN Holders Can Send Request");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const RedirectDirectFunction = async (appointmentId) => {
    try {
      setTimeout(() => {
        navigate("/health-carer-login", {
          state: { ApptId: appointmentId },
        });
      }, 500);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    HCLikeDislike();
  }, []);

  const HCLikeDislike = async () => {
    try {
      axios
        .get(`${getHCLikedAppointment}/${userData?._id}`)
        .then((res) => {
          let listUserTypeList = res?.data?.data;
          setFavourite(listUserTypeList?.likedAppointment);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const [rating, setRating] = useState(0);
  useEffect(() => {
    CheckClientRating();
  }, []);

  const CheckClientRating = async () => {
    try {
      axios
        .get(`${getClientRating}/${clientId}`)
        .then((res) => {
          let listUserTypeList = res?.data?.data;
          setRating(
            listUserTypeList?.roundedAverageRating !== null
              ? listUserTypeList?.roundedAverageRating
              : 0
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const renderRatingIcons = () => {
    const ratingIcons = [];

    for (let i = 0; i < rating; i++) {
      ratingIcons.push(<GradeIcon key={i} className="rating-cion-sty" />);
    }

    return rating > 0 ? ratingIcons : "";
  };

  let residentailAddress,
    homeAddress,
    communityAddress,
    respiteddress,
    silSdaAddress,
    displayAddress;

  residentailAddress = clientUserData?.address
    ? clientUserData?.address?.addressLine1 +
    ", " +
    clientUserData?.address?.city +
    ", " +
    stateData +
    ", " +
    clientUserData?.address?.postalCode
    : "";

  homeAddress =
    clientDetails?.homeCare?.length > 0
      ? clientDetails?.homeCare[0]?.addressLine1 +
      ", " +
      clientDetails?.homeCare[0]?.city +
      ", " +
      stateDataHome +
      ", " +
      clientDetails?.homeCare[0]?.postalCode
      : "";

  communityAddress =
    clientDetails?.communityAccess?.address?.length > 0
      ? clientDetails?.communityAccess?.address[0]?.addressLine1 +
      ", " +
      clientDetails?.communityAccess?.address[0]?.city +
      ", " +
      stateDataComm +
      ", " +
      clientDetails?.communityAccess?.address[0]?.postalCode
      : "";

  respiteddress =
    clientDetails?.respiteAndsta?.address?.length > 0
      ? clientDetails?.respiteAndsta?.address[0]?.addressLine1 +
      ", " +
      clientDetails?.respiteAndsta?.address[0]?.city +
      ", " +
      stateDataResp +
      ", " +
      clientDetails?.respiteAndsta?.address[0]?.postalCode
      : "";

  silSdaAddress =
    clientDetails?.silAndSda?.address?.length > 0
      ? clientDetails?.silAndSda?.address[0]?.addressLine1 +
      ", " +
      clientDetails?.silAndSda?.address[0]?.city +
      ", " +
      stateDataSil +
      ", " +
      clientDetails?.silAndSda?.address[0]?.postalCode
      : "";

  //displayAddress

  let homeAddressCord,
    residentailCode,
    communityAddressCord,
    respiteddressCord,
    silSdaAddressCord,
    displayCord;


  residentailCode = clientDetails?.address?.length > 0
    ? clientDetails?.address?.coordinates
    : "";


  homeAddressCord =
    clientDetails?.homeCare?.length > 0
      ? clientDetails?.homeCare[0]?.homeCareCoordinate?.coordinates
      : "";

  communityAddressCord =
    clientDetails?.communityAccess?.address?.length > 0
      ? clientDetails?.communityAccess?.address[0]?.communityAccessCoordinate
        ?.coordinates
      : "";

  respiteddressCord =
    clientDetails?.respiteAndsta?.address?.length > 0
      ? clientDetails?.respiteAndsta?.address[0]?.respiteAndstaCoordinate
        ?.coordinates
      : "";

  silSdaAddressCord =
    clientDetails?.silAndSda?.address?.length > 0
      ? clientDetails?.silAndSda?.address[0]?.silAndSdaCoordinate?.coordinates
      : "";

  switch (locationData?.name) {
    case "Homecare":
      displayAddress = homeAddress !== undefined && homeAddress;
      displayCord = homeAddressCord !== undefined && homeAddressCord;

      break;
    case "Community Access":
      displayAddress = communityAddress !== undefined && communityAddress;
      displayCord = communityAddressCord !== undefined && communityAddressCord;

      break;
    case "Respite/STA":
      displayAddress = respiteddress !== undefined && respiteddress;
      displayCord = respiteddressCord !== undefined && respiteddressCord;

      break;
    case "SIL/SDA":
      displayAddress = silSdaAddress !== undefined && silSdaAddress;
      displayCord = silSdaAddressCord !== undefined && silSdaAddressCord;

      break;
    default:
      displayAddress = residentailAddress !== undefined && residentailAddress;
      displayCord = residentailCode !== undefined && residentailCode;
  }

  const mapStyles = {
    height: "332px",
    width: "100%",
  };

  const isValidCoordinates = (coords) => {
    return (
      coords && typeof coords.lat === "number" && typeof coords.lng === "number"
    );
  };

  const defaultCenter = isValidCoordinates(displayCord)
    ? displayCord
    : { lat: -35.3081, lng: 149.1244 };

  useEffect(() => {
    HandleClientChange();
  }, [clientId]);
  const [ansData, setAnsData] = useState([]);
  const HandleClientChange = () => {
    axios
      .get(`${getClientRiskAssesment}/${clientId}`)
      .then((res) => {
        setAnsData(res?.data?.data[0]?.riskAssessment);
      })
      .catch((err) => {
        setAnsData([]);
        console.log(err);
      });
  };
  return (
    <>
      <Box id="view-details" className=" client-module-view-details">
        <Box>
          {pathValue !== "insideLogin" ? (
            <>
              <WebHeader />
              <Box mb={5}>{/* <h3>View Details</h3> */}</Box>
            </>
          ) : (
            ""
          )}

          <Box className="view-details-main-box webContainer">
            {pathValue !== "insideLogin" ? (
              <>
                <Grid
                  mb={3}
                  container
                  className="d-flex padding-left-20 padding-right-20 header-topsection"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Box id="breadcrumb" className="ps-0">
                      <Link to="/pickup-shifts">
                        <ArrowBackIosIcon />
                      </Link>
                      <Box className="breadcrumb-inr">
                        <h4 className="d-flex align-items-center ">
                          {" "}
                          View Details{" "}
                        </h4>
                        <Box className="breadcrumbs">
                          <span className="breadcrumbs-link"> Home </span>{" "}
                          <span className="breadcrumbs-icon">{">"}</span>
                          <span className="breadcrumbs-link"> MarketPlace </span>{" "}
                          <span className="breadcrumbs-icon">{">"}</span>
                          <span className="breadcrumbs-link"> Pickup Shift </span>{" "}
                          <span className="breadcrumbs-icon">{">"}</span>
                          <Link className="breadcrumbs-link">
                            {" "}
                            View Details{" "}
                          </Link>{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}

            <Box className=" view-details-pickupshift">
              <Box className="d-flex align-items-start">
                <Box className="view-detail-profile-image">
                  <Avatar aria-label="recipe" className="explore-icon">
                    <img
                      src={
                        clientUserData?.image?.location
                          ? clientUserData?.image?.location
                          : noImage
                      }
                      alt="explore.png"
                    />
                  </Avatar>
                </Box>
                <Box className="view-detail-profile-content">
                  <Box className="d-flex align-items-center ">
                    <Typography className="profile-title mobile-profile-view">
                      {clientUserData?.otherTitle +
                        " " +
                        clientUserData?.firstName +
                        " " +
                        clientUserData?.lastName}
                    </Typography>
                    <>{renderRatingIcons()}</>
                  </Box>

                  <span className="view-porfile-category">
                    {/* NDIS Number :{" "}
                    {clientDetails?.planDetails?.ndisClient === true
                      ? clientDetails?.planDetails?.ndisNumber
                      : ""} */}
                    NDIS Number : ******
                  </span>

                  <Box className="view-detail-client-address card-details-pickup d-flex align-items-center">
                    <Box className="d-flex align-items-center">
                      <img src={MapImage} />
                      <Typography variant="body2" component="div">
                        {
                          // displayAddress = silSdaAddress;
                          //displayCord = silSdaAddressCord;
                        }

                        {displayAddress}
                      </Typography>
                    </Box>
                    {
                      Type !== "findSupport" &&
                      <Box
                        className="d-flex align-items-center card-icon-pickup"
                        sx={{ ml: 3 }}
                      >
                        <img
                          src={locationData?.image?.location}
                          alt={locationData?.name}
                          title={locationData?.name}
                        />
                        &nbsp;
                        <Typography
                          className="exp-txt"
                          title={
                            locationData !== undefined && locationData !== null
                              ? locationData?.name
                              : "NA"
                          }
                        >
                          {locationData !== undefined && locationData !== null
                            ? locationData?.name
                            : "NA"}
                        </Typography>
                        &nbsp;
                      </Box>
                    }

                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="view-details-divider">
              <Box className="divider" sx={{ mt: 3 }}></Box>
              {/* view details tabs start  */}
            </Box>
            <Box className="view-tap-box">
              <Tabs
                centered
                // className="myaccounttabs observation obsevarion-tabs-bg"
                scrollButtons="auto"
                variant="scrollable"
                className="tab-container border-none myaccounttabs observation obsevarion-tabs-bg"
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
                allowScrollButtonsMobile
              >
                {/* {<><p className="tab-text"> List View </p></>} */}
                <Tab
                  className="view-tab"
                  iconPosition="start"
                  label="About"
                  {...a11yProps(0)}
                />

                <Tab
                  mt={2}
                  className="view-tab"
                  iconPosition="start"
                  label="Medical Details"
                  {...a11yProps(1)}
                />
                {Type === "findSupport" ?
                  <Tab
                    mt={2}
                    className="view-tab"
                    iconPosition="start"
                    label="Support Availability"
                    {...a11yProps(2)}
                  /> : ""
                }
              </Tabs>

              <Box className="view-detail-panel ">
                <TabPanel value={tabValue} index={0}>
                  <Grid
                    container
                    spacing={2}
                    className="about-panel-txt"
                    sx={{ mt: 1 }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="d-flex align-items-start">
                        <Typography>Name:</Typography>
                        <span>
                          {clientUserData?.otherTitle +
                            " " +
                            clientUserData?.firstName +
                            " " +
                            clientUserData?.lastName}
                        </span>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="d-flex align-items-start">
                        <Typography>NDIS Client:</Typography>
                        <span>
                          {clientDetails?.planDetails?.ndisClient === true
                            ? "Yes"
                            : "No"}
                        </span>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="d-flex align-items-start">
                        <Typography>Looking For:</Typography>

                        {/* <Box
                          className="d-flex align-items-center"
                          sx={{ ml: 3 }}
                         > */}

                        <Stack direction="row" sx={{ ml: 3 }}>
                          <img
                            className="provider-view-img"
                            src={locationData?.image?.location}
                            alt={locationData?.name}
                            title={locationData?.name}
                          />
                          &nbsp;
                          <Typography
                            className="exp-txt"
                            title={
                              locationData !== undefined &&
                                locationData !== null
                                ? locationData?.name
                                : "NA"
                            }
                          >
                            {locationData !== undefined && locationData !== null
                              ? locationData?.name
                              : "NA"}
                          </Typography>
                          &nbsp;
                        </Stack>

                        {/* </Box> */}
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="d-flex align-items-start">
                        <Typography>Location: </Typography>

                        {/* <Box
                          className="d-flex align-items-center"
                          sx={{ ml: 3 }}
                         > */}
                        <Stack direction="row" sx={{ ml: 3 }}>
                          <img
                            className="provider-view-img view-pro-resp"
                            src={locationData?.image?.location}
                            alt={locationData?.name}
                          />
                          {console.log("locationData", locationData)}

                          <Typography>
                            {`${displayAddress}`}
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="pt-0 view-detail-panel-grid"
                    >
                      <Box className="google-map">
                        <GoogleMap
                          center={defaultCenter}
                          zoom={10}
                          mapContainerStyle={mapStyles}
                          options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                          }}
                        >
                          {isValidCoordinates(displayCord) && (
                            <Marker position={displayCord} />
                          )}
                        </GoogleMap>
                      </Box>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel
                  value={tabValue}
                  index={1}
                  className="view-details-document-panel"
                >
                  <>
                    {medicationData ? (
                      <>
                        <Grid
                          container
                          spacing={2}
                          className="about-panel-txt"
                          sx={{ mt: 1 }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className="pt-0 view-detail-panel-grid"
                          >
                            <Box className="d-flex align-items-start">
                              <Typography>Issue Type:</Typography>
                              <span>
                                {medicationData?.medicalHistory?.issueType}
                              </span>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className="pt-0 view-detail-panel-grid"
                          >
                            <Box className="d-flex align-items-start">
                              <Typography>Health Issue:</Typography>
                              <span>{healthIssueData?.issueCategory}</span>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="pt-0 view-detail-panel-grid"
                          >
                            <Box className="d-flex align-items-start">
                              <Typography>Sub Category:</Typography>
                              <span>
                                {healthIssueData?.isSubCategory === true
                                  ? "Yes"
                                  : "No"}
                                &nbsp;
                                {healthIssueData?.isSubCategory === true ? (
                                  <>
                                    {`(`}
                                    {medicationData?.medicalHistory?.subCategory.map(
                                      (id, index) => {
                                        const healthIssue =
                                          healthIssueData?.subCategory.find(
                                            (issue) => issue._id === id
                                          );
                                        return healthIssue ? (
                                          <React.Fragment key={healthIssue._id}>
                                            {healthIssue.issueSubCategory}

                                            {index !==
                                              medicationData?.medicalHistory
                                                ?.subCategory.length -
                                              1
                                              ? ", "
                                              : ""}
                                          </React.Fragment>
                                        ) : null;
                                      }
                                    )}
                                    {`)`}
                                  </>
                                ) : null}
                              </span>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="pt-0 view-detail-panel-grid"
                          >
                            <Box className="d-flex align-items-start">
                              <Typography>Other Medical Issues:</Typography>
                              <span>
                                {
                                  medicationData?.medicalHistory
                                    ?.otherMedicalIssues
                                }
                              </span>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="pt-0 view-detail-panel-grid"
                          >
                            <Box className="d-flex align-items-start">
                              <Typography>Allergies:</Typography>
                              <Box className="view-tab-chip">
                                {medicationData?.medicalHistory?.allergies
                                  ?.length > 0 ? (
                                  medicationData?.medicalHistory?.allergies?.map(
                                    (alNm, index) => (
                                      <Chip key={index} label={alNm} />
                                    )
                                  )
                                ) : (
                                  <Chip label="NA" />
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Typography>
                      {/* "No Medical Record Found" */}
                      </Typography>
                    )}

                    <Grid
                      container
                      spacing={2}
                      className="about-panel-txt"
                      sx={{ mt: 1 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="pt-0 view-detail-panel-grid"
                      >
                        {ansData && ansData?.length !== 0 && (
                        <Accordion professionalDetailsExpand="panel2">
                          <AccordionSummary
                            className="accrdingbg-color"
                            expandIcon={
                              <ArrowDropDownIcon className="accrdingbg-icon-color" />
                            }
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Typography component="div">
                              <b>Check Risk Assesment</b>
                            </Typography>
                          </AccordionSummary>

                          <Box
                            className="form-heading-section"
                            sx={{
                              borderRadius: "4px",
                              p: 1,
                              mb: 2,
                            }}
                          >
                            <AccordionDetails>
                              <Grid
                                className="personal-info"
                                container
                                spacing={2}
                              >
                                {/* <Box className="d-flex align-items-start"> */}

                                {ansData && ansData?.length !== 0 ? (
                                  ansData?.map((item, index) => (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        key={index}
                                        className="pt-0 view-detail-panel-grid"
                                      >
                                        <List sx={{ width: "100%" }}>
                                          <ListItem className="listborder">
                                            <ListItemText
                                              primary={
                                                <>
                                                  <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                  >
                                                    <Typography
                                                      component="div"
                                                      className="sh-risk-lable"
                                                    >
                                                      {index + 1}.{" "}
                                                      {item?.question}
                                                    </Typography>

                                                    {item?.answer == "Yes" ? (
                                                      <>
                                                        <div>
                                                          <CheckCircleIcon
                                                            fontSize="medium"
                                                            className="sh-risk-check"
                                                          />
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div>
                                                          <CancelIcon
                                                            fontSize="medium"
                                                            className="sh-risk-croscheck"
                                                          />
                                                        </div>
                                                      </>
                                                    )}
                                                  </Stack>
                                                </>
                                              }
                                              secondary={
                                                <>
                                                  {item?.answer == "Yes" &&
                                                    item?.note !== "" ? (
                                                    <>
                                                      <Typography
                                                        component="div"
                                                        marginLeft="5px"
                                                      >
                                                        {item?.note}
                                                      </Typography>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Grid>
                                    </>
                                  ))
                                ) : (
                                  <Typography>
                                    {/* {" "}
                                    Form not filled by client.{" "} */}
                                  </Typography>
                                )}

                                {/* </Box> */}
                              </Grid>
                            </AccordionDetails>
                          </Box>
                        </Accordion>
                        )}
                        {medicationData === undefined && ansData?.length === 0 && (
                         <>
                           <Stack
                             direction="column"
                             spacing={2}
                             className="no-data-available"
                             sx={{justifyContent: "center",alignItems: "center"}}
                           >
                             <img src={nodata} alt="No Data" />
                             <Typography component="div">
                               No Medical details has been added
                             </Typography>
                           </Stack>
                       </>
                      )
                    }
                      </Grid>
                    </Grid>
                  </>
                </TabPanel>

                <TabPanel value={tabValue} index={2} style={{ width: '100%' }}>
                  <AvailabilityCalender
                    props={{ clientId: clientId }}
                  />
                </TabPanel>
              </Box>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ mt: 3, mb: 5 }}
                className="d-flex align-items-center justify-content-center"
              >
                <>{Type !== "findSupport" && <> {hcCheckId !== undefined && hcCheckId === userData?._id ? (
                  <></>
                ) : (
                  <Stack direction="row" spacing={2}>
                    {userData?._id !== undefined && userData?._id !== null ? (
                      <>

                        {apptHc !== undefined && apptHc !== null ? (
                          <>
                            {pendingCount >= hcBlock?.publishLiveRosterLimit ? (
                              <>
                                <Button
                                  variant="contained"
                                  className=""
                                  disabled="true"
                                >
                                  Try after some time
                                </Button>
                              </>
                            ) : (
                              <>
                                {apptHc?.includes(userData?._id) ? (
                                  <Button
                                    variant="contained"
                                    className="res-iconbtn"
                                    onClick={() => {
                                      addRequestFunction(
                                        ApptId,
                                        userId,
                                        "reject",
                                        Type,
                                        clientId
                                      );
                                    }}
                                    startIcon={<SendIcon />}
                                  >
                                    Cancel Request
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    className="res-iconbtn"
                                    startIcon={<SendIcon />}
                                    onClick={() => {
                                      addRequestFunction(
                                        ApptId,
                                        userId,
                                        "send",
                                        Type,
                                        clientId
                                      );
                                    }}
                                  >
                                    Send Request
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <Button
                            variant="contained"
                            className="res-iconbtn"
                            startIcon={<SendIcon />}
                            onClick={() => {
                              RedirectDirectFunction(ApptId);
                            }}
                          >
                            Request
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          className="res-iconbtn"
                          startIcon={<SendIcon />}
                          onClick={() => {
                            RedirectDirectFunction(ApptId);
                          }}
                        >
                          Request
                        </Button>
                      </>
                    )}

                    {userData?._id !== undefined && userData?._id !== null ? (
                      <Button
                        variant="outlined"
                        className="fav-iconbtn"
                        startIcon={
                          favourite?.includes(ApptId) ? (
                            <FavoriteIcon style={{ color: "#15A07D" }} />
                          ) : (
                            <FavoriteBorderOutlinedIcon />
                          )
                        }
                        onClick={() => {
                          if (favourite?.includes(ApptId)) {
                            addLikeApptFunction(ApptId, userId, "dislike");
                          } else {
                            addLikeApptFunction(ApptId, userId, "like");
                          }
                        }}
                      >
                        {favourite?.includes(ApptId)
                          ? "Remove From Favourites"
                          : "Add To Favourites"}
                      </Button>
                    ) : (
                      <Link to="" className="explore-view-link">
                        <div onClick={() => RedirectDirectFunction(ApptId)}>
                          <Button
                            variant="outlined"
                            className="fav-iconbtn"
                            startIcon={<FavoriteBorderOutlinedIcon />}
                          >
                            Add To Favourites
                          </Button>
                        </div>
                      </Link>
                    )}
                  </Stack>
                )}</>}
                </>

              </Grid>
            </Box>
          </Box>

          {pathValue !== "insideLogin" ? (
            <>
              <WebFooter />
            </>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};

export default ViewDetail;
